@import '../../../theme/variables';

@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrollAnim {
  0% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-80vh);
  }
  95% {
    transform: translateY(-80vh);
  }
  100% {
    transform: translateY(0);
  }
}

span {
  font-weight: 700;
}

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2vw;
  margin-top: 3vh;
  width: 46.5vw;
  height: 95vh;
  background-color: $color-light;
  border: 1px solid rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(80px);
  border-radius: 0.5vw;
  padding: 2% 2%;
  opacity: 0;
  animation: slide 30s;
}

.menu {
  &__title {
    font-size: $font-size-xl;
    font-weight: $font-regular;
    text-align: center;
    padding-top: 1vh;
    padding-bottom: 5vh;
    border-bottom: 1px solid #7e8d94;
  }
}

.list {
  overflow-y: hidden;

  &__box {
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 26.5vh;
    border-bottom: 1px solid #7e8d94;
    animation: scrollAnim 30s infinite;
    padding: 0 20px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  &__image {
    width: 5.5vw;
    height: 5.5vw;
    border-radius: 50%;
    border: 0.27vw solid #ffffff;
    filter: drop-shadow(0px 1px 12px rgba(0, 0, 0, 0.25));
    flex-shrink: 0;
  }

  &__title {
    text-align: left;
    margin-left: 30px;
    min-width: 100px;
    font-size: $font-size-lx;
    font-weight: $font-semiBold;
    line-height: $line-height-xl;

    &Big {
      font-size: $font-size-xl;
      font-weight: $font-semiBold;
      line-height: $line-height-xl;
    }
  }

  &__content {
    text-align: left;
    margin-left: 30px;
    font-size: $font-size-m;
    font-weight: $font-regular;
    line-height: $line-height-m;
  }
}
