@import '../../../theme/variables';

@keyframes slide {
  0% {
    opacity: 0;
  }
  2% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.info__paragraph:nth-child(1) {
  animation-delay: 0s;
}
.info__paragraph:nth-child(2) {
  animation-delay: 8s;
}
.info__paragraph:nth-child(3) {
  animation-delay: 16s;
}
.info__paragraph:nth-child(4) {
  animation-delay: 24s;
}
.info__paragraph:nth-child(5) {
  animation-delay: 32s;
}
.info__paragraph:nth-child(6) {
  animation-delay: 40s;
}
.info__paragraph:nth-child(7) {
  animation-delay: 48s;
}

span {
  font-weight: 700;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 2vw;
  bottom: 2vh;
  width: 74.85vw;
  height: 18vh;
  background-color: $color-light;
  border: 1px solid rgba(0, 0, 0, 0.07);
  backdrop-filter: blur(80px);
  border-radius: 0.5vw;
  padding: 1% 2%;
  z-index: 1000;
  opacity: 0;
  animation: slide 56s;

  &__paragraph {
    color: #7c481d;
    width: 90%;
    font-size: 1.88vw;
    line-height: 2.6vw;
    text-align: center;
    letter-spacing: 1px;
    font-weight: $font-regular;
    position: absolute;
    opacity: 0;
    animation: slide 8s;
  }
}
