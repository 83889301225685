@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes scrollAnim {
  0% {
    transform: translateY(0);
  }
  45% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(75vh);
  }
  95% {
    transform: translateY(75vh);
  }
  100% {
    transform: translateY(0);
  }
}

.scroll {
  position: absolute;
  top: 1.5vw;
  left: 1%;
  width: 0.7vw;
  height: 95vh;
  z-index: 5000;
  background-color: rgba(236, 236, 236, 0.7);
  border-radius: 5vw;
  opacity: 0;
  animation: slide 30s;

  &__line {
    animation: scrollAnim 30s linear infinite;
    border-radius: 5vw;
    background-color: #1979d4;
    width: 0.7vw;
    height: 20vh;
  }
}
